import { Controller } from "stimulus";
import { createDropdown } from "./dropdown_controller";

export default class extends Controller {
  static targets = ["button", "content"];

  connect() {
    this.dropdownInstance = createDropdown(
      this.buttonTarget,
      this.contentTarget.innerHTML
    );
  }

  close() {
    if (this.dropdownInstance) {
      this.dropdownInstance.hide();
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filename", "button"];

  clickButton() {
    this.buttonTarget.click();
  }

  updateFilename(event) {
    if (this.buttonTarget.files) {
      this.filenameTarget.innerText = this.buttonTarget.files[0].name;
    }
  }
}

import ApplicationController from "./application_controller";
import flatpickr from "flatpickr";

const arrowLeftSvg = `
<?xml version="1.0" encoding="utf-8"?>
<svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd" stroke-linecap="round" transform="matrix(0, -1, -1, 0, 24.25, 24.25)">
    <g stroke="#424242" stroke-width="1.6">
      <g>
        <g>
          <path d="M8 10L12 14 16 10" transform="translate(-601 -203) translate(499 203) translate(102.25)"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`;

const arrowRightSvg = `
<?xml version="1.0" encoding="utf-8"?>
<svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd" stroke-linecap="round" transform="matrix(0, -1, 1, 0, 0.25, 24.25)">
    <g stroke="#424242" stroke-width="1.6">
      <g>
        <g>
          <path d="M8 10L12 14 16 10" transform="translate(-601 -203) translate(499 203) translate(102.25)"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`;

export default class extends ApplicationController {
  static targets = ["dateInput"];
  static values = { enableTime: Boolean, minDate: String, maxDate: String, blank: Boolean};

  connect() {
    flatpickr(this.dateInputTarget, {
      altInput: true,
      altFormat: this.dateFormat,
      defaultDate: this.defaultDate,
      enableTime: this.enableTimeValue,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
      monthSelectorType: "static",
      prevArrow: arrowLeftSvg,
      nextArrow: arrowRightSvg,
      disableMobile: "true"
    });
  }

  get defaultDate() {
    if (this.dateInputTarget.value || this.blankValue) {
      return null;
    } else if (this.minDateValue) {
      return this.minDateValue;
    } else {
      return new Date();
    }
  }

  get dateFormat() {
    return this.enableTimeValue ? "m/d/Y h:i K" : "m/d/Y";
  }
}

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["sortingSelector", "searchField"];
  static values = {
    reflexName: String,
  };

  onSortingChange() {
    this.stimulate(
      `${this.reflexNameValue}#morph_collection`,
      { 
        filter: this.sortingSelectorTarget.value,
        search: this.searchFieldTarget.value
      }
    );
  }
}

// Rendered from _flash_msg.html.erb partial on connect (right when partial is rendered) tp show 3.5s toast message.
import { Controller } from "stimulus"
import { Toast } from "./index";
import DOMPurify from "dompurify";

export default class extends Controller {
  static targets = [ "flashContainer", "flashMessage" ]

  connect() {
    const { flashMessage, flashType } = this.flashMessageTarget.dataset;

    if (!flashMessage || !flashType) return;

    flashType === "notice"
      ? Toast.fire({
          icon: "success",
          customClass: { container: "toast-container success" },
          title: DOMPurify.sanitize(flashMessage),
        })
      : Toast.fire({
          icon: "error",
          customClass: { container: "toast-container danger" },
          title: DOMPurify.sanitize(flashMessage),
        });
  }
}

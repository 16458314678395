import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["buttonContainer"];

  loadModalContent(event) {
    const newButtonElement = event.currentTarget.cloneNode(true);
    newButtonElement.dataset.reflex = event.currentTarget.dataset.pendingReflex;
    newButtonElement.dataset.action = "click->modal#closeModal";
    newButtonElement.className = "button mb-2 button--primary-blue";
    newButtonElement.innerText = I18n.buttons.confirm;
    this.appendButton(newButtonElement);
  }

  appendButton(buttonElement) {
    this.buttonContainerTarget.appendChild(buttonElement);
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["fileUploadSwitchSelector", "searchField", "fileUploadFilesizeSwitchSelector"]

  static values = {
    menteeId: String,
  };

  onSortingChange(event) {
    this.stimulate("FileUploadsReflex#morph_collection", {
      search: this.searchFieldTarget.value,
      ...this.sortingFilterValue(event)
    });
  }

  sortingFilterValue(event) {
    if(event.currentTarget.id === 'button-filesize') {
      return { filesize: this.fileUploadFilesizeSwitchSelectorTarget.value }
    } else {
      return { filename: this.fileUploadSwitchSelectorTarget.value }
    }
  }


  onSubmitSuccess() {
    if (this.menteeIdValue) {
      this.stimulate("FileUploadsReflex#morph_view_for_mentee", this.menteeIdValue);
    } else {
      this.stimulate("FileUploadsReflex#morph_view");
    }
    
    super.showSuccessToast(I18n.file_upload_notification.success);
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
    super.showDangerToast(_data.errors || I18n.file_upload_notification.error);
  }
}

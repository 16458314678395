import ApplicationController from "./application_controller";
import Sortable from "sortablejs";
import { MOBILE_BREAKPOINT } from "./side_menu_controller";
import { debounce } from "debounced";

export default class extends ApplicationController {
  static targets = ["inner"];

  connect() {
    super.connect();

    this.tasksContainer = document.querySelector("#edit-board-container");

    this.sortableInstance = Sortable.create(this.element, {
      group: "kanban-board",
      animation: 50,
      ghostClass: "task-card--ghost",
      onStart: this.onStart,
      onEnd: this.onEnd,
      onMove: debounce(this.scrollToColumn, { wait: 400 }),
      delay: 50,
      delayOnTouchOnly: true,
    });
  }

  disconnect() {
    this.sortableInstance.destroy();
  }

  onStart = () => {
    document.body.classList.add("grabbing-cursor");
    this.tasksContainer.classList.add("tasks-container__locked-scroll");
  };

  onEnd = ({ item, to, from, newIndex }) => {
    document.body.classList.remove("grabbing-cursor");
    this.tasksContainer.classList.remove("tasks-container__locked-scroll");

    const params = {
      rank: newIndex,
    };

    this.stimulate(
      "BoardColumnsReflex#update_status",
      item.dataset.boardColumnsBoardColumnId,
      params
    );
  };

  scrollToColumn = (event) => {
    const isMobileSize = window.innerWidth < MOBILE_BREAKPOINT - 1;
    const targetColumn = event.to;
    const column = targetColumn.closest(".tasks-column");

    if (isMobileSize && targetColumn && column) {
      this.tasksContainer.scroll(column.offsetLeft - 24, 0);
    }
  };

}

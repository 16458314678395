import { Controller } from "stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["select", "mentees"];
  static values = {
    defaultMenteeId: String 
  }

  connect() {
    const choices = new Choices(this.selectTarget, {
      placeholder: false,
      noChoicesText: "",
      noResultsText: "",
      itemSelectText: "",
      searchFields: ["label"],
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            return template(`
              <div
                class="${classNames.item} ${classNames.itemChoice} ${
              classNames.itemSelectable
            } ${data.value ? "" : "choices__hidden-placeholder"}"
                data-select-text="${this.config.itemSelectText}"
                data-choice data-choice-selectable
                data-id="${data.id}"
                data-value="${data.value}"
                ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
              >
                ${data.label}
              </div>
            `);
          },
        };
      },
    });

    this.selectTarget.addEventListener("addItem", ({ detail }) => {
      this.changeCheckboxValue(detail.value, true);
      choices.removeActiveItems();
    });

    const menteeCheckboxElements = Array.from(
      this.menteesTarget.querySelectorAll("input")
    );

    if (this.defaultMenteeIdValue) {
      this.changeCheckboxValue(this.defaultMenteeIdValue, true);
    }

    menteeCheckboxElements.forEach((menteeInput) => {
      this.changeMenteeVisibility(menteeInput.value, menteeInput.checked);
    });
  }

  changeCheckboxValue(id, checked) {
    const matchedInput = this.menteesTarget.querySelector(
      `input[value="${id}"]`
    );

    if (!matchedInput) return;

    matchedInput.checked = checked;

    this.changeMenteeVisibility(id, checked);
  }

  uncheckMentee({ currentTarget }) {
    this.changeCheckboxValue(currentTarget?.dataset?.menteeId, false);
  }

  changeMenteeVisibility(id, visible) {
    const matchedLabel = this.menteesTarget.querySelector(
      `[data-label-mentee-id="${id}"]`
    );

    if (!matchedLabel) return;

    matchedLabel.style.display = visible ? "flex" : "none";
  }
}

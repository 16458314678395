import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "button"];

  select({ currentTarget }) {
    const { value } = currentTarget.dataset;

    this.selectTarget.value = value;
    this.buttonTarget.innerText = currentTarget.innerText;

    const event = new Event("change");
    this.selectTarget.dispatchEvent(event);
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  onSubmitSuccess() {
    super.showSuccessToast(I18n.email_notification.success);
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
    super.showDangerToast(_data.errors || I18n.email_notification.error);
  }
}

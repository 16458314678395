// import ApplicationController from "./application_controller";
import ChartController from "./chart_controller";
import consumer from "channels/consumer";

export default class extends ChartController {
  static values = { id: String }

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "MetricsChannel", id: this.idValue },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    document.querySelector("#metrics-collection-container").innerHTML = data.html;
  }
}

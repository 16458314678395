import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "avatarButton"];

  clickButton() {
    this.buttonTarget.click();
  }

  updatePreview() {
    const file = this.buttonTarget.files && this.buttonTarget.files[0];
    if (!file) return;

    const imageElement = this.avatarButtonTarget.querySelector("img");

    if (imageElement) {
      imageElement.src = URL.createObjectURL(file);
    } else {
      const newImageElement = document.createElement("img");
      newImageElement.src = URL.createObjectURL(file);
      newImageElement.classList = "object-cover h-full mx-auto";
      this.avatarButtonTarget.appendChild(newImageElement);
    }
  }
}

import ApplicationController from "./application_controller";
import Dropzone from "dropzone";

export default class extends ApplicationController {
  static values = {
    menteeId: String,
  };

  onSubmitSuccess() {
    if (this.menteeIdValue) {
      this.stimulate(
        "FileUploadsReflex#morph_view_for_mentee",
        this.menteeIdValue
      );
    } else {
      this.stimulate("FileUploadsReflex#morph_view");
    }

    super.showSuccessToast(I18n.file_upload_notification.success);
  }

  connect() {
    super.connect();

    const successCallback = this.onSubmitSuccess.bind(this);

    Dropzone.autoDiscover = false;
    const dropzoneOptions = {
      url: "/file_uploads",
      paramName: "file_upload[file]",
      params: { "file_upload[mentee_ids][]": this.menteeIdValue },
      headers: {
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
      success: successCallback,
      maxFilesize: 25,
    };

    const uploader = document.querySelector("#fileUploadsDropzone");
    new Dropzone(uploader, dropzoneOptions);
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "noteDateSwitchSelector",
    "noteTitleSwitchSelector",
    "searchField",
    "sortingSelectorMentee",
  ];

  static values = {
    menteeId: String,
  };

  onSortingChange(event) {
    this.stimulate("NotesReflex#morph_collection", {
      search: this.searchFieldTarget.value,
      mentee: this.menteeFilterValue(),
      ...this.sortingFilterValue(event),
    });
  }

  sortingFilterValue(event) {
    if (event.currentTarget.id === "button-date") {
      return { date: this.noteDateSwitchSelectorTarget.value };
    } else {
      return { title: this.noteTitleSwitchSelectorTarget.value };
    }
  }

  menteeFilterValue() {
    return this.hasSortingSelectorMenteeTarget
      ? this.sortingSelectorMenteeTarget.value
      : "";
  }

  onSubmitSuccess() {
    if (this.menteeIdValue) {
      this.stimulate("NotesReflex#morph_view_for_mentee", this.menteeIdValue);
    } else {
      this.stimulate("NotesReflex#morph_view");
    }

    super.showSuccessToast(I18n.note_notification.success);
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
    super.showDangerToast(_data.errors || I18n.note_notification.error);
  }
}

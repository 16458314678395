import tippy from "tippy.js";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    tippy(this.buttonTarget, {
      content: this.buttonTarget.dataset.tooltipContent,
      theme: "light",
      duration: 100,
    });
  }
}

import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import { Toast } from "./index";
import DOMPurify from "dompurify";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  showSuccessToast(message) {
    Toast.fire({
      icon: "success",
      customClass: { container: "toast-container success" },
      title: DOMPurify.sanitize(`${message}`),
    });
  }

  showWarningToast(message) {
    Toast.fire({
      timer: 5000,
      icon: "warning",
      customClass: { container: "toast-container warning" },
      title: DOMPurify.sanitize(`${message}`),
    });
  }

  showDangerToast(message) {
    Toast.fire({
      icon: "error",
      customClass: { container: "toast-container danger" },
      title: DOMPurify.sanitize(`${message}`),
    });
  }

  triggerAutofocus() {
    const focusElement = document.querySelector('[autofocus]')
    if (focusElement) {
      focusElement.focus()

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value
      focusElement.value = ''
      focusElement.value = value
    }
  }

  beforeReflex(element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError(element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted(element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  beforeEnterDetail () {
    const event = new CustomEvent('mentee:enter-detail', {})
    document.dispatchEvent(event)
  }

  afterShowForm () {
    this.triggerAutofocus()
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    metricId: String,
    menteeId: String,
  };

  onSubmitSuccess() {
    this.stimulate(
      "MetricDataPointReflex#morph_view",
      this.metricIdValue,
      this.menteeIdValue
    );
  }

  onSubmitError(event) {
    const [_data, _status] = event.detail;
    super.showDangerToast(_data.errors);
  }
}

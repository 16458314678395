import tippy from "tippy.js";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "content"];

  connect() {
    this.tippy = tippy(this.buttonTarget, {
      content: this.contentTarget.innerHTML,
      allowHTML: true,
      theme: "success",
      duration: 100,
      trigger: "click",
      interactive: true,
      arrow: false,
      placement: "bottom-end",
      zIndex: 2,
    });
  }

  closeDropdown() {
    if (this.tippy) {
      this.tippy.hide();
    }
  }
}

import { Controller } from "stimulus";
import getElementsByData from "../packs/utils/getElementsByData";

export default class extends Controller {
  static targets = ["modalProvider", "modalContainer"];

  openModal({ currentTarget }) {
    document.querySelector("body").classList.add("no-scroll");
    const modalName = currentTarget.dataset.modalButton;

    if (!modalName) return;

    const modals = getElementsByData("modal-name", this.modalProviderTarget);

    const matchedModal = modals.find(
      (modalElement) => modalElement.dataset.modalName === modalName
    );

    const modalContent = matchedModal.content.cloneNode(true);

    this.modalContainerTarget.appendChild(modalContent);
  }

  closeModal() {
    document.querySelector("body").classList.remove("no-scroll");
    this.modalContainerTarget.innerHTML = "";
  }

  switchModal(event) {
    this.closeModal();
    this.openModal(event);
  }
}

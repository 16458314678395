import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["button", "value", "arrow"];

  flipValue(event) {
    const oldValue = this.valueTarget.value;

    this.valueTarget.value = event.currentTarget.dataset.buttonValue;
    event.currentTarget.dataset.buttonValue = oldValue;
    event.currentTarget.dataset.buttonSecondaryValue = this.valueTarget.value;
    this.flipArrow();
  }

  flipArrow() {
    this.arrowTarget.classList.toggle("arrow-switch--flipped");
  }

  nextArrowSymbol = (symbol) => (symbol === "▲" ? "▼" : "▲");
}

import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    const input = this.element;
    const cursorEnd = input.value.length;
    if (input.setSelectionRange) {
      input.focus();
      input.select();
    }
  }
}
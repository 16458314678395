import ApplicationController from './appointment_controller';
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";

const VALIDATION_IGNORED_FIELD_TYPES = ["file", "reset", "submit", "button"];

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.element.setAttribute("novalidate", true);
    this.element.addEventListener("blur", this.revalidateField, true);
    this.element.addEventListener("input", this.revalidateField, true);
    this.element.addEventListener("submit", this.submit);
    this.element.addEventListener("ajax:beforeSend", this.onSubmit);
    this.element.addEventListener("ajax:error", this.resetSubmitting.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("blur", this.revalidateField);
    this.element.removeEventListener("input", this.revalidateField);
    this.element.removeEventListener("submit", this.submit);
    this.element.removeEventListener("ajax:beforeSend", this.onSubmit);
    this.element.removeEventListener("ajax:error", this.resetSubmitting.bind(this))
  }

  revalidateField = event => {
    if (event.target.classList.contains("blurOnly-validate") && event.type !== "blur") return;

    this.validateField(event.target);
  };

  submit = (event) => {
    if (!this.validateForm()) {
      event.preventDefault();
      this.firstInvalidField.focus();
      return;
    }

    this.setSubmitting();
  };

  validateForm() {
    let isValid = true;

    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field))
        isValid = false;
    });

    return isValid;
  }

  validateField(field) {
    if (!this.shouldValidateField(field)) return true;

    const errorMessage = this.getErrorMessage(field);
    const isValid = !errorMessage;

    field.classList.toggle("invalid", !isValid);
    this.refreshErrorForInvalidField(field, errorMessage);

    return isValid;
  }

  shouldValidateField(field) {
    return (
      !field.disabled && !VALIDATION_IGNORED_FIELD_TYPES.includes(field.type)
    );
  }

  getErrorMessage(field) {
    const { dataset } = field;
    const { validation: translatedError } = I18n;

    if (field.checkValidity && !field.checkValidity()) {
      return field.validationMessage;
    }

    if ("minValue" in dataset) {
      const minValue = parseInt(dataset.minValue);
      const isValid = parseInt(field.value) >= minValue;

      if (!isValid) return `${translatedError.min_value} ${minValue}`;
    }

    if ("maxValue" in dataset) {
      const maxValue = parseInt(dataset.maxValue);
      const isValid = parseInt(field.value) <= maxValue;

      if (!isValid) return `${translatedError.max_value} ${maxValue}`;
    }

    if ("minDate" in dataset) {
      const { minDate } = dataset;
      const isValid = !isBefore(new Date(field.value), new Date(minDate));

      if (!isValid) return `${translatedError.min_date} ${minDate}`;
    }

    if ("maxDate" in dataset) {
      const { maxDate } = dataset;
      const isValid = !isAfter(new Date(field.value), new Date(maxDate));

      if (!isValid) return `${translatedError.max_date} ${maxDate}`;
    }

    return "";
  }

  refreshErrorForInvalidField(field, errorMessage) {
    this.removeExistingErrorMessages(field);

    if (errorMessage) this.showErrorForInvalidField(field, errorMessage);
  }

  removeExistingErrorMessages(field) {
    const fieldContainer = field.parentNode;

    if (!fieldContainer) return;

    const existingErrorMessageElements = this.queryErrorElements(
      fieldContainer
    );

    if (existingErrorMessageElements.length)
      existingErrorMessageElements.forEach((errorElement) => {
        errorElement.parentNode.removeChild(errorElement);
      });
  }

  showErrorForInvalidField(field, errorMessage) {
    field.insertAdjacentHTML(
      "afterend",
      this.buildFieldErrorHtml(errorMessage)
    );
  }

  buildFieldErrorHtml(errorMessage) {
    return `<p class="text-sm text-red-500 absolute input-error-message" data-error-field>${errorMessage}</p>`;
  }

  queryErrorElements(container) {
    return Array.from(container.querySelectorAll("[data-error-field]"));
  }

  setSubmitting() {
    this.submitButtons.forEach((button) => {
      button.classList.add("button--submitting");
      button.value = "Submitting...";
      button.disabled = true;
    });
  }

  setSubmitting() {
    this.submitButtons.forEach((button) => {
      button.classList.add("button--submitting");
      button.dataset.preSubmitValue = button.dataset.preSubmitValue ?? button.value;
      button.value = I18n.buttons.submitting;
      button.disabled = true;
    });
  }

  resetSubmitting() {
    this.submitButtons.forEach((button) => {
      button.classList.remove("button--submitting");
      if(button.dataset.preSubmitValue) button.value = button.dataset.preSubmitValue;
      button.disabled = false;
    });
  }

  toggleNoteShareVisibility() {
    document.querySelector("#share-with-select-box-container").classList.toggle("vanish");
  }

  get formFields() {
    return Array.from(this.element.elements);
  }

  get firstInvalidField() {
    return this.formFields.find((field) => !field.checkValidity());
  }

  get submitButtons() {
    const buttons = this.element.querySelectorAll('button[type="submit"]');
    const inputs = this.element.querySelectorAll('input[type="submit"]');

    return [...Array.from(buttons), ...Array.from(inputs)];
  }
}

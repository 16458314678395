import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["button", "value"];

  changeButtonColors(event) {
    if (event.target.classList.contains("switch-button--selected")) return;

    this.buttonTargets.forEach((button) => {
      button.classList.toggle("switch-button--selected");
    });
  }

  updateValue(event) {
    this.valueTarget.value = event.target.dataset.buttonValue;
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    menteeId: String,
  };

  onSubmitSuccess(event) {
    if (this.menteeIdValue) {
      this.stimulate(
        "BoardColumnsReflex#morph_view",
        this.menteeIdValue,
      );
    } else {
      super.showDangerToast(
        I18n[`task_${event.currentTarget.dataset.mode}_notification`].error
      );
    }

    super.showSuccessToast(
      I18n[`task_${event.currentTarget.dataset.mode}_notification`].success
    );
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
    super.showDangerToast(
      _data.errors ||
        I18n[`task_${event.currentTarget.dataset.mode}_notification`].error
    );
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['metricCards']

  connect() {
    this.metricCardsTargets.slice(1).forEach(element => {
      setTimeout(() => {
        element.classList.remove("card--expanded");
      }, 0);
    });
  }
}

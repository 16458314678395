// import ApplicationController from "./application_controller";
import ChartController from "./chart_controller";
import consumer from "channels/consumer";

export default class extends ChartController {
  static values = { 
    props: Object,
  }

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "MetricChannel", id: this.propsValue.metric_id },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const parsedMessage = JSON.parse(data.message)

    if (parsedMessage.remove) {
      document.querySelector(`#metric-container-${parsedMessage.id}`)?.remove();
      return
    }
    if (data.message) {
      document.querySelector(`#${this.propsValue.id}`)?.remove();
      const chartContainer = document.querySelector(
        `#metric-chart-container-${this.propsValue.id}`
      );
      if (!chartContainer.firstChild) {
        const newCanvas = document.createElement("canvas");
        newCanvas.setAttribute("id", this.propsValue.id);
        chartContainer.appendChild(newCanvas);
        super.createChart(parsedMessage);
      }
    }
  }
}

import ApplicationController from "./application_controller";
import { muteYourAudio,
         muteYourVideo,
         unmuteYourAudio,
         unmuteYourVideo,
         handleRemoteTrackEnabled,
         handleRemoteTrackDisabled,
         handleRemoteDisabled,
         handleRemoteEnabled,
         zoomTrack,
         isMobile,
       } from "../packs/utils/video/helpers";
import { createScreenTrack } from "../packs/utils/video/screen_share_helpers"

export default class extends ApplicationController {
  static values = { 
    token: String,
    roomName: String,
    roomSid: String,
    userIdentity: String
  }

  connect() {
    const { connect, createLocalTracks, createLocalVideoTrack, LocalVideoTrack } = require("twilio-video");
    const localMediaContainer = document.querySelector("#local-media-videopreview");
    const remoteMediaVideoPreview = document.querySelector("#remote-media-videopreview");
    const remoteMediaAudioPreview = document.querySelector("#remote-media-audiopreview");
    const muteVideoBtn = document.querySelector("#mute-video-btn");
    const muteAudioBtn = document.querySelector("#mute-audio-btn");
    const shareScreen = document.querySelector("#share-screen-button");

    const localScreenPreview = document.querySelector("#local-media-screenshare");
    // The LocalVideoTrack for your screen.
    let screenTrack;

    createLocalVideoTrack().then(track => {
      let trackElement = track.attach();
      trackElement.addEventListener('click', () => { zoomTrack(trackElement, "#local-media"); });
      localMediaContainer.appendChild(trackElement);
    });

    createLocalTracks({
      audio: true,
      video: { width: 640 }
    }).then(localTracks => {
      return connect(this.tokenValue, {
        name: this.roomNameValue,
        tracks: localTracks,
        maxAudioBitrate: 16000,
        networkQuality: {local:1, remote: 1}
      });
    }).then(room => {
      console.log(`Successfully joined a Room: ${room}`);

      // This code loops through all existing participants in the room, 
      // attaches their tracks, and then also listens for new automatic 
      // subscriptions that will occur whenever someone else joins the room. 
      // This way, whenever someone else joins the room, their tracks will automatically 
      // be shared with every remote participant.
      room.participants.forEach(participant => {
        participant.tracks.forEach(publication => {
          if (!publication.track) {
            handleRemoteDisabled(publication)
          }
        });
      
        participant.on("trackSubscribed", track => {
          let trackElement = track.attach();
          trackElement.addEventListener('click', () => { zoomTrack(trackElement, "#remote-media-div"); });
          // attach media from remote participant
          if (track.isEnabled) {
            remoteMediaVideoPreview.appendChild(trackElement);
          }

          track.on("disabled", () => { 
            handleRemoteDisabled(track);
          });
          track.on("enabled", () => {
            handleRemoteEnabled(track);
          });
        });
      });

      // runs when the participantConnected event is emitted on the room
      room.on("participantConnected", participant => {
        console.log(`A remote Participant connected: ${participant}`);
        // loops over any tracks belonging to the participant and attaches them to the DOM
        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            handleRemoteTrackEnabled(track);
            handleRemoteTrackDisabled(track);
          }
          publication.on("subscribed", handleRemoteTrackEnabled);
          publication.on("subscribed", handleRemoteTrackDisabled);

          // removes video and audio previews when remote participant leaves the room
          publication.on("unsubscribed", track => {
            track.detach().forEach(element => {
              element.remove();
            });
            [remoteMediaAudioPreview, remoteMediaVideoPreview].forEach(el => {
              el.classList.add("vanish");
            });
          });
        });
      
        participant.on("trackSubscribed", track => {
          remoteMediaVideoPreview.appendChild(track.attach());
        });
      });

      // removes media previews from remote participat screen when 
      // local participant ends sharing screen
      room.on('trackUnpublished', publication => {
        remoteMediaVideoPreview.lastElementChild.remove();
      });

      room.on("disconnected", room => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach(publication => {
          publication.track.stop();
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });
      });

      muteAudioBtn.addEventListener("click", function() {
        const mute = !muteAudioBtn.classList.contains("muted");
    
        if(mute) {
          muteYourAudio(room);
          muteAudioBtn.classList.add("muted");
        } else {
          unmuteYourAudio(room);
          muteAudioBtn.classList.remove("muted");
        }
      });

      muteVideoBtn.addEventListener("click", function() {
        const videoOff = !muteVideoBtn.classList.contains("video-off");
    
        if(videoOff) {
          muteYourVideo(room);
          muteVideoBtn.classList.add("video-off");

        } else {
          unmuteYourVideo(room);
          muteVideoBtn.classList.remove("video-off");
        }
      });

      shareScreen.addEventListener("click", shareScreenHandler);

      function shareScreenHandler(event) {
        event.preventDefault();
        if (!screenTrack) {
          createScreenTrack(720, 1280).then(scTrack => {
            screenTrack = scTrack;
            
            localScreenPreview.appendChild(screenTrack.attach());
            room.localParticipant.publishTrack(screenTrack);
            screenTrack.mediaStreamTrack.onended = () => { shareScreenHandler() };
          }).catch(() => {
            alert("Could not share the screen.");
          });
        }
        else {
          room.localParticipant.unpublishTrack(screenTrack);
          screenTrack.stop();
          screenTrack.detach().forEach(element => {
            element.remove();
          });
          screenTrack = null;
        }
      };
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  disconnect() {
    this.closeRoom(this.roomSidValue, this.userIdentityValue);
    window.location.reload();
  }

  closeRoom(roomSid, userIdentity) {
    fetch(`close?room_sid=${roomSid}&user_identity=${userIdentity}`);
  }
}

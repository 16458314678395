import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['menteeSelector', 'dateSelector']

  onChange() {
    this.stimulate(
      "DateReflex#show_mentee_time_note",
      this.menteeSelectorTarget.value,
      this.dateSelectorTarget.value
    );
  }
}
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    document.addEventListener('stimulus-reflex:ready', this.fadeOut.bind(this));
    document.addEventListener('mentee:enter-detail', this.fadeIn.bind(this));
  }

  fadeIn() {
    this.element.classList.remove("transition-all", "duration-500", "ease-in-out", "opacity-0", "splashscreen--hidden")
  }

  fadeOut() {
    this.element.classList.add("transition-all", "duration-500", "ease-in-out", "opacity-0", "splashscreen--hidden")
  }
}

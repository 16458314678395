import tippy from "tippy.js";
import { Controller } from "stimulus";

export const createDropdown = (buttonElement, contentElement) => {
  if (!buttonElement || !contentElement) {
    return;
  }

  return tippy(buttonElement, {
    content: contentElement,
    allowHTML: true,
    theme: "success",
    duration: 100,
    trigger: "click",
    interactive: true,
    arrow: false,
    placement: "bottom-end",
    zIndex: 2,
  });
};

export default class extends Controller {
  static targets = ["button", "content"];

  connect() {
    createDropdown(this.buttonTarget, this.contentTarget.innerHTML);
  }
}

"use strict";

/**
 * Mute/unmute your media in a Room.
 * @param {Room} room - The Room you have joined
 * @param {'audio'|'video'} kind - The type of media you want to mute/unmute
 * @param {'mute'|'unmute'} action - Whether you want to mute/unmute
 */
function muteOrUnmuteYourMedia(room, kind, action) {
  const publications = kind === "audio"
    ? room.localParticipant.audioTracks
    : room.localParticipant.videoTracks;

  publications.forEach(function(publication) {
    if (action === "mute") {
      publication.track.disable();
      handleLocalTrackDisabled(publication.track);
    } else {
      publication.track.enable();
      handleLocalTrackEnabled(publication.track);
    }
  });
}

/**
 * Mute your audio in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
export function muteYourAudio(room) {
  muteOrUnmuteYourMedia(room, "audio", "mute");
}

/**
 * Mute your video in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
export function muteYourVideo(room) {
  muteOrUnmuteYourMedia(room, "video", "mute");
}

/**
 * Unmute your audio in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
export function unmuteYourAudio(room) {
  muteOrUnmuteYourMedia(room, "audio", "unmute");
}

/**
 * Unmute your video in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
export function unmuteYourVideo(room) {
  muteOrUnmuteYourMedia(room, "video", "unmute");
}

/**
 * A RemoteParticipant muted or unmuted its media.
 * @param {Room} room - The Room you have joined
 * @param {function} onMutedMedia - Called when a RemoteParticipant muted its media
 * @param {function} onUnmutedMedia - Called when a RemoteParticipant unmuted its media
 * @returns {void}
 */
export function participantMutedOrUnmutedMedia(room, onMutedMedia, onUnmutedMedia) {
  room.on("trackSubscribed", function(track, publication, participant) {
    track.on("disabled", function() {
      return onMutedMedia(track, participant);
    });
    track.on("enabled", function() {
      return onUnmutedMedia(track, participant);
    });
  });
}

/**
 * Handles remote truck disabled 
 * Hide the associated <video> element and show an avatar image.
 * this is called on second user machine!
 * @param {track} track - remote user media track
 * @returns {void}
 */
export function handleRemoteTrackDisabled(track) {
  track.on("disabled", () => {
    handleRemoteDisabled(track);
  });
}

export function handleRemoteDisabled(track) {
  const remoteMediaDiv = document.querySelector("#remote-media-videopreview");
  if (track.kind === "video" && !track.isTrackEnabled) {
    while (remoteMediaDiv.firstChild) {
      remoteMediaDiv.firstChild.remove()
    }
    const otherUserAvatarPlaceholder = document.querySelector("#other-user-img-avatar-for-chat-hidden");
    const otherUserImageElement = otherUserAvatarPlaceholder.firstElementChild.cloneNode(true);

    remoteMediaDiv.insertAdjacentElement("beforeend", otherUserImageElement)
  }

  if (track.kind === "audio" && !track.isTrackEnabled) {
    const inactiveIcon = document.querySelector("#audio-remote-inactive-icon");
    inactiveIcon.classList.remove("vanish");
  }
}

export function handleLocalTrackDisabled(track) {
  const localMediaDiv = document.querySelector("#local-media-videopreview");
  if (track.kind === "video") {
    while (localMediaDiv.firstChild) {
      localMediaDiv.firstChild.remove()
    }
    const currentUserAvatarPlaceholder = document.querySelector("#current-user-img-avatar-for-chat-hidden");
    const currentUserImageElement = currentUserAvatarPlaceholder.firstElementChild.cloneNode(true);

    localMediaDiv.insertAdjacentElement("beforeend", currentUserImageElement)
  }

  if (track.kind === "audio") {
    const inactiveIcon = document.querySelector("#audio-local-inactive-icon");
    inactiveIcon.classList.remove("vanish");
  }
}

/**
 * Handles remote truck enabled 
 * Hide the avatar image and show the associated <video> element.
 * this is called on second user machine!
 * @param {track} track - remote user media track
 * @returns {void}
 */
 export function handleRemoteTrackEnabled(track) {
   track.on("enabled", () => {
    handleRemoteEnabled(track);
  });
}

export function handleRemoteEnabled(track) {
  const remoteMediaDiv = document.querySelector("#remote-media-videopreview");
  if (track.kind === "video") {
    while (remoteMediaDiv.firstChild) {
      remoteMediaDiv.firstChild.remove()
    }
    remoteMediaDiv.appendChild(track.attach())
  }

  if (track.kind === "audio") {
    const inactiveIcon = document.querySelector("#audio-remote-inactive-icon");
    inactiveIcon.classList.add("vanish");
  }
}

export function handleLocalTrackEnabled(track) {
  const localMediaDiv = document.querySelector("#local-media-videopreview");
  if (track.kind === "video") {
    while (localMediaDiv.firstChild) {
      localMediaDiv.firstChild.remove()
    }
    localMediaDiv.appendChild(track.attach())
  }

  if (track.kind === "audio") {
    const inactiveIcon = document.querySelector("#audio-local-inactive-icon");
    inactiveIcon.classList.add("vanish");
  }
}

export function onTrackPublished(publishType, publication, view) {
  if (publishType === 'unpublish') {
    if (publication.track) {
      publication.track.detach(view);
      view.srcObject = null;
    }

    publication.on('subscribed', track => {
      track.detach(view);
      view.srcObject = null;
    });
  }
}

export function zoomTrack(trackElement, selector) {
  const container = document.querySelector(selector)
  if (!trackElement.classList.contains("participantZoomed")) {
    // zoom in
    container.childNodes.forEach(participant => {
      if (typeof(participant.classList) !== "undefined" && participant.classList?.contains("participant")) {
        participant.childNodes.forEach(track => {
          if (track === trackElement) {
            track.classList.add("participantZoomed");
            container.classList.remove("relative");
            document.body.classList.add("hide-side-menu");
          } else {
            track.classList.add("participantHidden");
            container.classList.remove("relative");
            document.body.classList.add("hide-side-menu");
          }
        });
      }
    });
  }
  else {
    // zoom out
    container.childNodes.forEach(participant => {
      if (participant.classList && participant.classList.contains("participant")) {
        participant.childNodes.forEach(track => {
          if (track === trackElement) {
            track.classList.remove("participantZoomed");
            container.classList.add("relative");
            document.body.classList.remove("hide-side-menu");
          } else {
            track.classList.remove('participantHidden');
            container.classList.add("relative");
            document.body.classList.remove("hide-side-menu");
          }
        });
      }
    });
  }
};

/**
 * Whether the web app is running on a mobile browser.
 * @type {boolean}
 */
 export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();
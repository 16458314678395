import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  onSubmitSuccess() {
    this.stimulate("SettingsReflex#show_info");

    super.showSuccessToast(I18n.update_profile_notification.success);
  }

  onChangePasswordSuccess() {
    this.stimulate("SettingsReflex#show_edit_form");

    super.showSuccessToast(I18n.change_password_notification.success);
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
    super.showDangerToast(
      _data.errors || I18n.update_profile_notification.error
    );
  }
}

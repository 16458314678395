// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import smoothscroll from "smoothscroll-polyfill";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./stylesheets/application.scss";
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";

import "channels";

require("tailwindcss/base");
require("tailwindcss/components");
require("tailwindcss/utilities");
require("flatpickr");

require("chart.js");
import debounced from "debounced";

smoothscroll.polyfill();

debounced.initialize({ input: { wait: 200 } });

Rails.start();
ActiveStorage.start();
require("turbolinks").start();
require("trix");
require("@rails/actiontext");
import "controllers";

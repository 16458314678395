import FormController from './form_controller'

export default class extends FormController {
  static targets = [
    "startDateField",
    "endDateField",
    "sortingSelector",
    "sortingSelectorOverdue",
    "searchField",
    "metricsSwitchSelector",
    "targetField",
    "targetMinField",
    "targetMaxField"
  ];

  static values = {
    msg: String,
    menteeId: String,
  };

  validateDateFields() {
    if (!this.startDateFieldTarget || !this.endDateFieldTarget) return;

    const startDateField = this.startDateFieldTarget.querySelector("input[type='text']")
    const endDateField = this.endDateFieldTarget.querySelector("input[type='text']")

    const startDate = new Date(startDateField.value);
    const endDate = new Date(endDateField.value);
    const dateMismatch = endDate < startDate;
    const errorMessage = dateMismatch ? I18n.validation.start_date_end_date : "";
    
    startDateField.classList.toggle("invalid", dateMismatch);
    endDateField.classList.toggle("invalid", dateMismatch);

    this.refreshErrorForInvalidField(startDateField, errorMessage);
    this.refreshErrorForInvalidField(endDateField, errorMessage);
  }

  validateTargetField() {
    if (!this.targetFieldTarget.value || !this.targetMinFieldTarget.value || !this.targetMaxFieldTarget.value) return;
    
    const targetField = parseFloat(this.targetFieldTarget.value);
    const targetFieldMin = parseFloat(this.targetMinFieldTarget.value);
    const targetFieldMax = parseFloat(this.targetMaxFieldTarget.value);

    const targetMismatch =  targetField > targetFieldMax || targetField < targetFieldMin;
    const errorMessage = targetMismatch ? I18n.validation.target_out_of_bounds : "";

    this.targetFieldTarget.classList.toggle("invalid", targetMismatch);
    this.targetMinFieldTarget.classList.toggle("invalid", targetMismatch);
    this.targetMaxFieldTarget.classList.toggle("invalid", targetMismatch);

    this.refreshErrorForInvalidField(this.targetFieldTarget, errorMessage);
    this.refreshErrorForInvalidField(this.targetMinFieldTarget, errorMessage);
    this.refreshErrorForInvalidField(this.targetMaxFieldTarget, errorMessage);
  }

  setSelectedDaysInput(e) {
    const metricWeekDaysInput = document.querySelector(
      "#metric-form-input-specific_week_days"
    );
    const metricMonthDaysInput = document.querySelector(
      "#metric-form-input-monthly_on_days"
    );

    switch (e.target.value) {
      case "every_day":
        metricWeekDaysInput.classList.add("vanish");
        metricMonthDaysInput.classList.add("vanish");
        break;
      case "specific_week_days":
        metricWeekDaysInput.classList.remove("vanish");
        metricMonthDaysInput.classList.add("vanish");
        break;
      case "monthly_on_days":
        metricWeekDaysInput.classList.add("vanish");
        metricMonthDaysInput.classList.remove("vanish");
        break;
    }
  }

  onSubmitSuccess() {
    this.stimulate(
      "MetricsReflex#morph_view",
      this.msgValue,
      this.menteeIdValue
    );
  }

  onSortingChange() {
    this.stimulate("MetricsReflex#morph_collection", this.menteeIdValue, {
      filter: this.sortingSelectorTarget.value,
      overdue: this.sortingSelectorOverdueTarget.value,
      search: this.searchFieldTarget.value,
      time: this.metricsSwitchFilterValue(),
    });
  }

  handleOverdueSelectorVisibility() {
    if(this.metricsSwitchFilterValue() === 'past') {
      this.sortingSelectorOverdueTarget.parentNode.classList.add('vanish')
    } else {
      this.sortingSelectorOverdueTarget.parentNode.classList.remove('vanish')
    }
  }

  metricsSwitchFilterValue() {
    return this.hasMetricsSwitchSelectorTarget
      ? this.metricsSwitchSelectorTarget.value
      : "";
  }

  onSubmitError(event) {
    const [_data, _status] = event.detail;
    super.showDangerToast(_data.errors);
  }
}

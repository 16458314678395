import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [
    "sortingSelectorType",
    "sortingSelectorMentee",
    "appointmentSwitchSelector",
    "dateSelector",
    "searchField",
    "filters",
    "todayAppointmentsList",
    "nextAppointmentsList",
    "todayAppointmentsSeparator",
    "nextAppointmentsSeparator",
    "recurrenceSummary",
    "repeatInput",
    "repeatEveryInput",
    "startsOnInput",
    "endsAfterInput",
    "endsOnInput"
  ];

  static values = {
    menteeId: String,
  };

  connect() {
    super.connect();
    if (this.hasRecurrenceSummaryTarget) this.evaluateRecurrenceSummary();
  }

  changeMeetingLinkVisibility(e) {
    const checked = e.target.checked;
    const meetingLinkInputContainer = document.querySelector("#appoitment-form-meeting-link-input-container");
    const meetingLinkInputField = document.querySelector("#appointment_meeting_link");

    if (checked === false) {
      meetingLinkInputContainer.classList.remove("vanish");
      meetingLinkInputField.setAttribute("required", "required")
    } else {
      meetingLinkInputContainer.classList.add("vanish");
      meetingLinkInputField.removeAttribute("required")
    }
  }

  clearDateSelection() {
    this.dateSelectorTarget.value = "";
  }

  onSortingChange() {
    this.stimulate("AppointmentsReflex#morph_collection", {
      type: this.sortingSelectorTypeTarget.value,
      time: this.appointmentSwitchSelectorTarget.value,
      date: this.dateSelectorTarget.value,
      mentee: this.menteeFilterValue(),
      search: this.searchFieldTarget.value,
    });
  }

  menteeFilterValue() {
    return this.hasSortingSelectorMenteeTarget
      ? this.sortingSelectorMenteeTarget.value
      : "";
  }

  onSubmitSuccess(event) {
    if (this.menteeIdValue) {
      this.stimulate(
        "AppointmentsReflex#morph_view_for_mentee",
        this.menteeIdValue
      );
    } else {
      this.stimulate("AppointmentsReflex#morph_view");
    }

    super.showSuccessToast(
      I18n[`appointment_${event.currentTarget.dataset.mode}_notification`]
        .success
    );
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
    super.showDangerToast(
      _data.errors ||
        I18n[`appointment_${event.currentTarget.dataset.mode}_notification`]
          .error
    );
  }

  toggleFiltersVisibility({ currentTarget }) {
    this.filtersTarget.classList.toggle(
      "appointments__filters-container--expanded"
    );
    currentTarget.classList.toggle(
      "appointments__toggle-filters-button--expanded"
    );
  }

  toggleRecurringAppotiment(e) {
    e.target.setAttribute("value", e.target.checked);
    document
      .querySelector("#recurring-appointment-form")
      .classList.toggle("appointments__filters-container");
  }

  checkEndsOn() {
    const onDate = document.querySelector(`#recurring-appointment-on_date`);
    const afterDate = document.querySelector(
      `#recurring-appointment-after_date`
    );

    afterDate.classList.toggle("appointments__filters-container--expanded");
    onDate.classList.toggle("appointments__filters-container--expanded");
  }

  get recurrenceSummaryMessage() {
    const summaryBuffer = [];
    const selectedIndex = this.repeatInputTarget.selectedIndex;
    const recurrence = this.repeatInputTarget.options[selectedIndex].text;

    summaryBuffer.push(recurrence)
    const repeatEvery = this.repeatEveryInputTarget.value
    summaryBuffer.push(I18n.date.with_interval)
    summaryBuffer.push(repeatEvery)

    const startDate = new Date(this.startsOnInputTarget.value)
    if (!isNaN(startDate.getTime())) {
      summaryBuffer.push(I18n.date.from);
      summaryBuffer.push(
        new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(startDate)
      );
    }

    const endDate = new Date(this.endsOnInputTarget.value)
    if (!isNaN(endDate.getTime())) {
      summaryBuffer.push(I18n.date.till);
      summaryBuffer.push(
        new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(endDate)
      );
    }

    return summaryBuffer.join(' ');
  }

  evaluateRecurrenceSummary() {
    const recurrenceSummary = document.querySelector("#recurrence-summary-text");
    recurrenceSummary.innerHTML = this.recurrenceSummaryMessage;
  }
}

import { Controller } from "stimulus";
import { MOBILE_BREAKPOINT } from "./side_menu_controller";

export default class extends Controller {
  connect() {
    this.mainContent = document.querySelector(".main-content");
    this.mainContent.classList.add("main-content--tasks");

    const todoColumn = this.element.querySelector("#task-todo-column");

    if (!todoColumn) return;

    const isMobileSize = window.innerWidth < MOBILE_BREAKPOINT - 1;

    if (isMobileSize) {
      this.element.scroll(todoColumn.offsetLeft - 24, 0);
    }
  }

  disconnect() {
    this.mainContent.classList.remove("main-content--tasks");
  }
}

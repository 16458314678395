import format from "date-fns/format";
import parse from "date-fns/parse";

import ApplicationController from "./application_controller";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

const getOrCreateTooltip = (chart) => {
  let tooltipElement = chart.canvas.parentNode.querySelector("div");

  if (!tooltipElement) {
    tooltipElement = document.createElement("div");
    tooltipElement.classList.add("chart__tooltip");
    tooltipElement.style.opacity = 1;
    const content = document.createElement("div");
    content.classList.add("chart__tooltip-content");

    tooltipElement.appendChild(content);
    chart.canvas.parentNode.appendChild(tooltipElement);
  }

  return tooltipElement;
};

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  annotationPlugin
);

export default class extends ApplicationController {
  static values = {
    props: Object,
  };

  connect() {
    const chartContainer = document.querySelector(
      `#metric-chart-container-${this.propsValue.id}`
    );
    if (!chartContainer.firstChild) {
      const newCanvas = document.createElement("canvas");
      newCanvas.setAttribute("id", this.propsValue.id);
      chartContainer.appendChild(newCanvas);
      this.createChart(this.propsValue);
    }
  }

  disconnect() {
    document.querySelector(`#${this.propsValue.id}`)?.remove();
  }

  createChart(props) {
    const data = props.data;
    const { target } = props.options;
    const meetOrExceed = props.options.target_opt === "meet_or_exceed";
    const yTickOffset = Math.ceil(0.05*Math.abs(props.options.max_scale_number - props.options.min_scale_number));

    const targetGoal = target && {
      line1: {
        type: "line",
        yMin: props.options.target,
        yMax: props.options.target,
        borderColor: "#99bff2",
        borderWidth: 2,
      },
    };

    const ctx = document.getElementById(props.id);

    new Chart(ctx, {
      type: "line",
      propsOptions: props.options,
      data: {
        datasets: [
          {
            label: props.options.title,
            tension: 0,
            data: data,
            borderColor: ["rgba(64, 124, 202, 1)"],
            borderWidth: 3,
            segment: {
              borderColor: (ctx) =>
                skipped(ctx, "rgb(0,0,0,0.2)") || down(ctx, "rgb(192,75,75)"),
              borderDash: (ctx) => skipped(ctx, [6, 6]),
            },
          },
        ],
      },
      options: {
        spanGaps: true,
        interaction: {
          mode: "point",
        },
        events: ["click"],
        elements: {
          point: {
            radius: 6,
            backgroundColor: "#fff",
          },
        },
        plugins: {
          autocolors: false,
          annotation: {
            annotations: { ...targetGoal },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: this.externalTooltipHandler,
          },
        },
        maintainAspectRatio: true,
        scales: {
          x: {
            display: true,
            autoSkip: false,
            ticks: {
              callback: function (value, index, values) {
                return format(
                  parse(data[value].x, "MM/dd/yyyy", new Date()),
                  "MM/dd"
                );
              },
            },
          },
          y: {
            min: props.options.min_scale_number - yTickOffset,
            max: props.options.max_scale_number + yTickOffset,
            ticks: {
              callback: function (value, _index, _values) {
                return value >= props.options.min_scale_number && value <= props.options.max_scale_number ? value : ""
              },
              stepSize: props.options.ticks_step_size,
            },
          },
        },
      },
      plugins: [
        {
          afterLayout: (chart) => {
            if (!target) return;

            const { ctx } = chart;
            ctx.save();

            const orange = "#ef7622";
            const blue = "#407cca";
            const aboveColor = meetOrExceed ? blue : orange;
            const belowColor = meetOrExceed ? orange : blue;

            const { y } = chart.scales;
            const threshold = y.getPixelForValue(target) - 5;
            const gradient = ctx.createLinearGradient(0, y.top, 0, y.bottom);
            const offset = (1 / y.bottom) * threshold;

            gradient.addColorStop(0, aboveColor);
            gradient.addColorStop(offset, aboveColor);
            gradient.addColorStop(offset, belowColor);
            gradient.addColorStop(1, belowColor);

            chart.data.datasets[0].borderColor = gradient;

            ctx.restore();
          },
        },
      ],
    });
  }

  externalTooltipHandler = ({ chart, tooltip }) => {
    const tooltipElement = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipElement.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const content = document.createElement("div");

      const tooltipValue = tooltip.dataPoints[0]?.formattedValue;
      const tooltipDate = tooltip.dataPoints[0]?.label;

      if (tooltipValue && tooltipDate) {
        const parsedDate = parse(tooltipDate, "MM/dd/yyyy", new Date());
        const formattedDate = format(parsedDate, "MMMM dd");

        const pointId = JSON.parse(this.element.dataset.metricPoints).find(
          (dataPoint) =>
            dataPoint["reading_time"] === format(parsedDate, "yyyy-MM-dd")
        )?.id;

        const dateElement = document.createElement("span");
        dateElement.classList.add("tooltip__date");
        dateElement.innerText = formattedDate;

        const value = document.createElement("strong");
        value.innerText = tooltipValue;

        const valueElement = document.createElement("span");
        valueElement.classList.add("tooltip__value");
        valueElement.innerText = `${I18n.chart.value}: `;
        valueElement.appendChild(value);

        content.appendChild(dateElement);
        content.appendChild(valueElement);

        const button = document.createElement("button");

        button.type = "button";
        button.innerText = I18n.chart.delete;
        button.dataset.reflex = "click->MetricDataPointReflex#remove_point";
        button.dataset.pointId = pointId;
        button.dataset.metricId = this.propsValue["metric_id"];
        button.dataset.menteeId = this.element.dataset.menteeId;
        button.dataset.reflexDataset = "combined";
        button.classList.add("button");
        button.classList.add("button--light-red");
        button.classList.add("tooltip__remove-data-point-button");

        content.appendChild(button);
      }

      const contentRoot = tooltipElement.querySelector(
        ".chart__tooltip-content"
      );

      while (contentRoot.firstChild) {
        contentRoot.firstChild.remove();
      }

      contentRoot.appendChild(content);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipElement.style.opacity = 1;
    tooltipElement.style.left = positionX + tooltip.caretX + "px";
    tooltipElement.style.top = positionY + tooltip.caretY + "px";
    tooltipElement.style.font = tooltip.options.bodyFont.string;
    tooltipElement.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
}

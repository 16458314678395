import { Controller } from "stimulus";
import Cookies from "js-cookie";
import Turbolinks from "turbolinks";

const MENU_STATE_KEY = "side_menu_expanded";
export const MOBILE_BREAKPOINT = 768;

export default class extends Controller {
  connect() {
    const isMobileSize = window.innerWidth < MOBILE_BREAKPOINT - 1;
    const menuOpenByDefault = this.checkMenuSavedAsExpanded();

    if (isMobileSize && menuOpenByDefault) {
      this.toggleMenu();
    }
  }

  toggleMenu() {
    this.toggleSavedState();
    this.element.classList.toggle("menu--hidden");
  }

  toggleSavedState() {
    const currentState = this.checkMenuSavedAsExpanded();
    Cookies.set(MENU_STATE_KEY, !currentState);
    // Turbolinks caches previous cookie causing conflict
    Turbolinks.clearCache();
  }

  checkMenuSavedAsExpanded = () =>
    JSON.parse((Cookies.get(MENU_STATE_KEY) || "true").toLowerCase());

  openMobileMenu() {
    this.element.classList.remove("menu--hidden");
  }

  closeMobileMenu() {
    this.element.classList.add("menu--hidden");
  }
}
